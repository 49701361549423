import {
    CmsPageComponent as SourceCmsPageComponent,
} from 'SourceRoute/CmsPage/CmsPage.component';

import './CmsPage.override.style';

/** @namespace AlmusbahblendPwa/Route/CmsPage/Component */
export class CmsPageComponent extends SourceCmsPageComponent {
    // TODO implement logic
}

export default CmsPageComponent;
