import { connect } from 'react-redux';

import {
    CmsPageContainer as SourceCmsPageContainer,
    mapDispatchToProps,
    mapStateToProps,
} from 'SourceRoute/CmsPage/CmsPage.container';

import { Page } from 'Component/Header/Header.config';
import history from 'Util/History';
import { debounce } from 'Util/Request/Debounce';
import { getUrlParam, isHomePageUrl } from 'Util/Url';

import { LOADING_TIME } from './CmsPage.config';

export {
    mapStateToProps,
    mapDispatchToProps,
};

/** @namespace AlmusbahblendPwa/Route/CmsPage/Container */
export class CmsPageContainer extends SourceCmsPageContainer {
    // TODO implement logic

    onPageLoad() {
        const {
            updateMeta,
            setHeaderState,
            updateBreadcrumbs,
        } = this.props;
        const { cmsPage } = this.props;
        const { location: { pathname } } = history;
        const {
            content_heading,
            meta_title,
            title,
            meta_description,
            meta_keywords,
        } = cmsPage;
        
        debounce(this.setOfflineNoticeSize, LOADING_TIME)();

        updateBreadcrumbs(cmsPage);
        updateMeta({
            title: meta_title || title,
            description: meta_description,
            keywords: meta_keywords,
            canonical_url: window.location.href,
        });

        if (!isHomePageUrl(pathname)) {
            setHeaderState({
                name: Page.CMS_PAGE,
                title: title, // changed by sanyasirao
                onBackClick: () => history.goBack(),
            });
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CmsPageContainer);
