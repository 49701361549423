import { connect } from 'react-redux';

import {
    HomePageContainer as SourceHomePageContainer,
    mapDispatchToProps,
    mapStateToProps,
} from 'SourceRoute/HomePage/HomePage.container';

import Footer from 'Component/Footer';
import CmsPage from 'Route/CmsPage';

export {
    mapStateToProps,
    mapDispatchToProps,
};

/** @namespace AlmusbahblendPwa/Route/HomePage/Container */
export class HomePageContainer extends SourceHomePageContainer {
    // TODO implement logic

    render() {
        return (
            <div block="HomePage">
                {/* <InstallPrompt /> */}
                <CmsPage { ...this.containerProps() } />
                <Footer isVisibleOnMobile />
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(HomePageContainer);
